/**
 * リダイレクトURL管理用middleware
 */
import { useRoutingState } from '~/composables/store/useRoutingStore';

const { setRedirectUrl } = useRoutingState();

// リダイレクトURLをセット
export default defineNuxtRouteMiddleware((to, from) => {
  // カード新規登録中はリダイレクトURLをセットしない
  if (from.path === '/card/create' || from.path === '/card/confirm') return;
  // 定期利用券購入フローではリダイレクトURLをセットしない
  if (!!from.query.passId || !!to.query.passId) return;

  // NOTE: ページ遷移が発生した場合のみ更新する
  if (from.path !== to.path) setRedirectUrl(from.fullPath);
});
